.navbar {
	.submenu-btn {
		font-family: var(--poppins);
	}
}

.fixHeader {
	.navbar-listItemLink {
		color: var(--black);

		&:hover {
			color: var(--brown);
		}
	}
}

@media (--media-navbar) {
	.navbar {
		position: fixed;
		width: 75%;
		height: 100%;
		right: 0;
		left: 0;
		top: 0;
		bottom: 0;
		transform: translateX(-100%);
		background-color: var(--white);
		transition: transform 0.6s cubic-bezier(0.83, 0, 0.17, 1);
		z-index: 2;
		padding: 50px 10px 30px;
		display: flex;
		flex-direction: column;

		&.active {
			transform: translateX(0%);
		}

		&-list {
			display: flex;
			flex-direction: column;
			text-align: center;
			gap: 50px;
		}

		&-listItemLink,
		.submenu-listItemLink {
			display: inline-block;
			color: var(--black);
			text-transform: uppercase;
			font-size: 20px;
			font-weight: 400;
		}

		&-listItem {
			&.active {
				pointer-events: none;

				.navbar-listItemLink {
					color: var(--brown);
				}
			}
		}

		.submenu-list {
			position: relative;
			max-height: 0;
			overflow: hidden;
			transition: max-height 0.3s;
			padding: 0 15px;
			margin-left: 10px;
			border-left: 6px solid var(--black);
		}

		.button {
			position: absolute;
			width: 15px;
			height: 15px;
			right: -23px;
			top: 50%;
			transform: translateY(-50%) scale(0.7);
			pointer-events: none;

			&::before,
			&::after {
				content: '';
				position: absolute;
				background-color: var(--black);
				transition: transform 0.25s ease-out;
			}

			/* Vertical line */
			&::before {
				top: 0;
				left: 50%;
				width: 2px;
				height: 100%;
				margin-left: -1px;
			}

			/* horizontal line */
			&::after {
				top: 50%;
				left: 0;
				width: 100%;
				height: 2px;
				margin-top: -1px;
			}
		}

		.submenu-btn {
			background-color: transparent;
			border: none;
			padding: 15px 10px 15px 0;
			text-transform: uppercase;
			font-size: 14px;
			color: var(--black);
			width: 100%;
			text-align: left;

			&.opened {
				.submenu-btnText::after {
					transform: rotate(225deg);
				}

				.button {
					cursor: pointer;

					&::before {
						transform: rotate(90deg);
					}

					&::after {
						transform: rotate(180deg);
					}
				}
			}
		}

		.submenu-btnText {
			position: relative;
			display: inline-block;
			pointer-events: none;
			font-weight: 700;
		}

		.socials {
			margin-top: auto;
			margin-bottom: 10px;
			display: flex;
			justify-content: center;

			svg {
				fill: var(--black);
				width: 30px;
				height: 30px;
			}
		}

		.socials-listItem {
			width: 30px;
			height: 30px;
		}

		.copyright {
			color: var(--black);
			font-size: 12px;
			line-height: 1.5;
			text-align: center;
		}
	}
}

@media (--media-desktop) {
	.navbar {
		max-width: var(--containerMaxWidth);
		margin: 0 auto;
		width: 100%;

		&-list {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			gap: 20px;
		}

		&-listItemLink {
			display: block;
			padding: 15px 5px;
			text-transform: uppercase;
			font-size: 13px;
			letter-spacing: 1px;
			transition: color 0.3s;
			color: var(--white);
			font-weight: 700;
			font-family: var(--oswald);

			&:hover {
				color: var(--brown);
			}
		}

		.submenu-listItemLink {
			display: block;
			white-space: nowrap;
			padding: 15px 5px;
			text-transform: uppercase;
			font-size: 10px;
			letter-spacing: 1px;
			color: var(--white);
			transition: color 0.3s;
			font-weight: 700;
		}

		&-listItem {
			transition: color 0.3s;
			position: relative;
			z-index: 1;

			&.active {
				pointer-events: none;

				.navbar-listItemLink,
				.submenu-listItemLink {
					color: var(--brown);
				}
			}

			.submenu-listItem:hover {
				.submenu-listItemLink {
					color: var(--brown);
				}
			}

			&:not(:last-child)::after {
				content: '/';
				position: absolute;
				color: var(--brown);
				top: 50%;
				transform: translateY(-50%);
				right: -15px;
				font-size: 10px;
			}
		}

		.submenu-list {
			position: absolute;
			opacity: 0;
			visibility: hidden;
			transition:
				opacity 0.3s,
				visibility 0.3s,
				transform 0.3s;
			box-shadow: 0 8px 16px rgb(0 0 0 / 15%);
			background-color: var(--white);
			padding: 10px;
			transform: translateY(10px);
			pointer-events: none;
		}

		.submenu-btnTextItem {
			color: var(--black);
			transition: color 0.3s;
			line-height: 10px;
		}

		&-listItem.dropdown {
			&:hover {
				.submenu-list {
					opacity: 1;
					visibility: visible;
					transform: translateY(0);
					pointer-events: auto;
				}

				.submenu-btnTextItem {
					color: var(--brown);
				}
			}
		}

		.submenu-btn {
			padding: 15px 5px;
			background-color: transparent;
			border: none;
			text-align: left;
			line-height: 16px;
			text-transform: uppercase;
			font-size: 10px;
			letter-spacing: 1px;
			font-weight: 700;
		}

		.submenu-btnTextItemCaret {
			display: inline-block;
			width: 0;
			height: 0;
			margin-left: 2px;
			vertical-align: middle;
			border-top: 4px solid;
			border-right: 4px solid transparent;
			border-left: 4px solid transparent;
		}

		.submenu-btnText {
			display: flex;
		}

		.button,
		.socials,
		.copyright {
			display: none;
		}
	}
}
