[title='recaptcha challenge'] {
	position: fixed;
	top: 0;
}

/* ///////////////////////////////////////////////////////////////////// */
* {
	box-sizing: border-box;
	margin-left: 0;
	margin-right: 0;
	-webkit-overflow-scrolling: touch;

	&::before,
	&::after {
		box-sizing: border-box;
	}
}

body {
	position: relative;
	font-family: var(--poppins);
	min-height: 100vh;
	color: var(--black);
	background-color: var(--white);

	&.overflow-y {
		overflow-y: hidden;
	}
}

main {
	background-color: inherit;
}

h1,
h2 {
	margin: 0;
}

img {
	max-width: 100%;
}

a {
	text-decoration: none;
}

.sprite-svg {
	position: absolute;
	overflow: hidden;
	width: 0;
	height: 0;
}

@media (--media-desktop) {
	input,
	textarea,
	button,
	a {
		&:focus-visible {
			border-color: var(--focusBorderColor);
			outline: 0;
			box-shadow: var(--boxShadowFocusColor);
		}
	}
}
