.pictureGenerator {
	position: relative;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;

	&.noRadius {
		border-radius: 0;
	}

	&:not(.transparent) {
		background-color: var(--gray);

		svg {
			width: 30%;
			height: 40%;
		}
	}

	&-image {
		position: absolute;
		width: 100%;
		height: 100%;
		inset: 0;
		transition: transform 0.3s;

		&.hasdisclaimer {
			filter: blur(11px);
		}
	}

	.picture-caption {
		text-align: right;
		text-transform: uppercase;
		font-style: italic;
		font-size: 12px;
		padding-right: 5px;
	}

	.picture-captionLink {
		color: var(--black);
	}

	&-ratio-16-9 {
		aspect-ratio: 16/9;
	}

	&-ratio-4-3 {
		aspect-ratio: 4/3;
	}

	&-ratio-2-3 {
		aspect-ratio: 2/3;
	}

	&-ratio-1-1 {
		aspect-ratio: 1/1;
	}

	&-ratio-130-33 {
		aspect-ratio: 130/33;
	}

	&-ratio-453-307 {
		aspect-ratio: 453/307;
	}
}

@media (--media-tablet) {
	.pictureGenerator {
		&-ratio-16-9--tablet {
			aspect-ratio: 16/9;
		}

		&-ratio-4-3--tablet {
			aspect-ratio: 4/3;
		}

		&-ratio-1-1--tablet {
			aspect-ratio: 1/1;
		}

		&-ratio-130-33--tablet {
			aspect-ratio: 130/33;
		}

		&-ratio-453-307--tablet {
			aspect-ratio: 453/307;
		}
	}
}

@media (--media-desktop) {
	.pictureGenerator {
		&-ratio-16-9--desktop {
			aspect-ratio: 16/9;
		}

		&-ratio-4-3--desktop {
			aspect-ratio: 4/3;
		}

		&-ratio-1-1--desktop {
			aspect-ratio: 1/1;
		}

		&-ratio-130-33--desktop {
			aspect-ratio: 130/33;
		}

		&-ratio-453-307--desktop {
			aspect-ratio: 453/307;
		}
	}
}
