.footer {
	background-color: var(--black);
	font-size: 12px;
	color: var(--lightWhite);

	&-container {
		max-width: var(--containerMaxWidth);
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 20px;
		padding: 30px 10px;
		text-align: center;
	}

	&-icon {
		display: block;
		width: 20px;
		height: 20px;

		svg {
			fill: var(--brown);
			width: 100%;
			height: 100%;
		}
	}

	&-socials {
		.socials-link {
			svg {
				fill: var(--lightWhite);
			}
		}
	}

	&-address,
	&-schedule,
	&-links {
		display: flex;
		flex-direction: column;
		gap: 10px;
		margin-bottom: 30px;
	}

	&-addressTitle,
	&-scheduleTitle,
	&-linksTitle {
		color: var(--brown);
		font-weight: 400;
		font-size: 18px;
		margin-bottom: 20px;
		font-family: var(--oswald);
	}

	&-addressText,
	&-scheduleText,
	&-linksListItemLink,
	&-year,
	&-webmaster {
		text-decoration: none;
		color: var(--lightWhite);
		font-size: 18px;
		font-weight: 200;
		line-height: 1.7;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		gap: 5px;
		font-family: var(--oswald);

		.footer-infoPhoneNumber {
			color: inherit;
		}
	}

	&-year {
		position: relative;
		padding-top: 10px;

		&::after {
			content: '';
			height: 1px;
			background-color: var(--white);
			position: absolute;
			top: 0;
			left: 50%;
			width: 70%;
			transform: translateX(-50%);
			opacity: 0.2;
		}
	}

	&-yearCopyrightLink,
	&-webmasterLink {
		font-weight: 400;
		color: var(--brown);
	}
}

@media (--media-desktop) {
	.footer {
		&-container {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			grid-template-areas:
				'socials socials socials'
				'address schedule links'
				'year year year'
				'webmaster webmaster webmaster';
			gap: 0 120px;
		}

		&-scheduleText,
		&-linksListItemLink,
		&-year,
		&-webmaster {
			align-items: center;
		}

		&-infoPhoneNumber,
		&-linksListItemLink {
			&:hover {
				color: var(--brown);
			}
		}

		&-socials {
			grid-area: socials;
		}

		.socials {
			justify-content: center;
			margin-bottom: 50px;
		}

		&-address {
			grid-area: address;

			.footer-addressText {
				text-align: left;
				align-self: flex-start;
			}
		}

		&-schedule {
			grid-area: schedule;
		}

		&-links {
			grid-area: links;
		}

		&-address,
		&-schedule,
		&-links {
			margin-bottom: 0;
			height: 100%;
		}

		&-linksList {
			display: flex;
			flex-direction: column;
			align-items: baseline;
		}

		&-year {
			grid-area: year;
			padding: 0;
			margin: 50px 0 10px;

			&::after {
				content: none;
			}
		}

		&-yearCopyright {
			display: flex;
			white-space: nowrap;
			gap: 5px;
		}

		&-webmaster {
			grid-area: webmaster;
		}

		&-scheduleText {
			align-items: flex-start;
			justify-content: flex-start;

			&:not(:last-child) {
				gap: 0;
				display: flex;
				flex-direction: column;
			}
		}
	}
}
