.cta,
.link {
	font-family: var(--oswald);
	display: flex;
	align-items: center;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: var(--white);
	background-color: var(--brown);
	border: none;
	border-radius: 30px;
	padding: 12px 35px;
}

.cta {
	&.disabled {
		cursor: not-allowed;
	}

	&-content {
		visibility: visible;
		opacity: 1;
		transition:
			visibility 0.3s,
			opacity 0.3s;
	}

	&-loader {
		display: none;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		place-items: center;
		visibility: hidden;
		opacity: 0;
		transition:
			visibility 0.3s,
			opacity 0.3s;
	}

	&.loading {
		pointer-events: none;

		.cta-loader {
			display: grid;
			opacity: 1;
			visibility: visible;
		}

		.cta-content {
			visibility: hidden;
			opacity: 0;
		}
	}
}

.link {
	font-family: var(--oswald);
	display: flex;
	align-items: center;
	font-size: 12px;
	text-transform: uppercase;
	letter-spacing: 1px;
	color: var(--white);
	background-color: var(--brown);
	border: none;
	border-radius: 30px;
	padding: 12px 35px;

	&-icon {
		width: 10px;
		height: 10px;
		display: flex;
		margin-left: 10px;

		svg {
			width: 100%;
			height: 100%;
			fill: var(--white);
		}
	}
}
