:root {
	--containerMaxWidth: 1100px;
	--navbarHeight: 70px;

	/* fonts */
	--poppins: poppins, sans serif;
	--oswald: oswald, sans serif;

	/* colors */
	--white: #fff;
	--lightWhite: #b8b8b8;
	--black: #18181d;
	--trueBlack: #000;
	--red: #e64e4e;
	--gray: #f2f2f2;
	--lightGray: #fafafa;
	--darkGray: #dbdbdb;
	--darkestGray: #999;
	--brown: #ca8e46;
	--gold: #ffd700;
	--green: #03924d;
	--transparent: rgb(255 255 255 / 0%);

	/* warning */
	--error: #721c24;
	--backgroundError: #f8d7da;
	--borderError: #f5c6cb;
	--success: #155724;
	--backgroundSuccess: #d4edda;
	--borderSuccess: #c3e6cb;

	/* shadow */
	--boxShadow: rgb(0 0 0 / 12%) 0px 2px 20px 0px;

	/* focus */
	--focusBorderColor: #80bdff;
	--boxShadowFocusColor: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

/* Navbar breakpoint */
@custom-media --media-navbar only screen and (max-width: 1024px);

/* Breakpoints */
@custom-media --media-mobile only screen and (max-width: 749px);
@custom-media --media-tablet only screen and (min-width: 750px);
@custom-media --media-desktop only screen and (min-width: 1024px);
@custom-media --media-desktop-large only screen and (min-width: 1200px);
