.topBar {
	position: fixed;
	top: 0;
	width: 100%;
	left: 0;
	right: 0;
	max-width: 100%;
	font-size: 13px;
	height: var(--navbarHeight);
	z-index: 10;
	transition:
		transform 0.3s,
		border-bottom 0.3s,
		background-color 0.3s;

	&-wrapper {
		height: 100%;
		display: flex;
		align-items: center;
		padding-left: 20px;
	}

	a {
		display: flex;
		color: var(--black);
	}

	svg {
		fill: var(--black);
	}

	&-logo {
		width: 130px;
		height: 30px;

		svg {
			width: 100%;
			height: 100%;
			fill: var(--white);
			transition: fill 0.3s;
		}
	}

	&-socials {
		display: none;
	}

	&-infos {
		display: none;
		align-items: center;
		padding-left: 15px;
		font-family: var(--oswald);
	}

	&-infoPhoneNumber {
		transition:
			color 0.3s,
			border-color 0.3s;
	}

	&-infosPhone,
	&-infosEmail {
		display: none;
		cursor: pointer;

		svg {
			width: 25px;
			height: 25px;
			transition: fill 0.3s;
		}
	}

	&-infoText {
		display: none;
		transition: color 0.3s;
	}
}

.fixHeader {
	.topBar {
		background-color: var(--white);
		box-shadow: 0 15px 10px -15px rgb(120 120 120 / 15%);

		&-logo {
			svg {
				fill: var(--black);
			}
		}
	}
}

@media (--media-desktop) {
	.topBar {
		height: 40px;
		box-shadow: none;
		padding: 0 10px;
		display: flex;
		transition: transform 0.3s;

		a {
			align-items: center;
			gap: 5px;
		}

		&-wrapper {
			height: 100%;
			width: 100%;
			display: flex;
			align-items: center;
			max-width: var(--containerMaxWidth);
			margin: 0 auto;
			padding: 0;
			line-height: normal;
		}

		&-socials {
			display: flex;
			margin-left: auto;
		}

		&-infos {
			display: flex;
			flex-direction: row;
			padding-left: 0;
			gap: 20px;

			svg {
				width: 13px;
				height: 13px;
				fill: var(--brown);
				margin-right: 5px;
			}
		}

		&-infoText {
			color: var(--white);
			display: block;
		}

		&-infosEmail {
			display: block;

			&:hover {
				.topBar-infoText {
					color: var(--brown);
				}
			}
		}

		&-infoPhoneNumber {
			padding: 3px 10px;
			margin-bottom: 0;
			border: 1px solid var(--transparent);
			border-radius: 25px;
		}

		&-infosPhone {
			display: block;
			color: var(--white);

			&:hover {
				.topBar-infoPhoneNumber {
					border: 1px solid var(--brown);
					color: var(--brown);
				}

				.topBar-infoText {
					color: var(--brown);
				}
			}
		}

		&-infosAddress {
			color: var(--white);
		}

		&-logo {
			display: none;
		}
	}

	.fixHeader {
		.topBar {
			background-color: unset;
			box-shadow: none;
			transform: translateY(-100%);
		}
	}
}
