.header {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	z-index: 10;
	background-color: var(--white);
	transition:
		background-color 0.5s ease,
		box-shadow 0.5s ease,
		max-width 0.3s ease,
		transform 0.3s;

	&-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 100%;
		width: var(--containerMaxWidth);
		max-width: 100%;
		margin: 0 auto;
	}

	&-wrapperFigure {
		display: none;

		svg {
			fill: var(--white);
			transition: fill 0.3s;
		}
	}

	&-wrapperOverlay {
		position: fixed;
		right: 0;
		left: 0;
		top: 0;
		bottom: 0;
		background-color: rgb(0 0 0 / 70%);
		transition:
			opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1),
			visibility 0.3s;
		opacity: 0;
		visibility: hidden;
		transition-delay: 0.5s;

		&.active {
			opacity: 1;
			visibility: visible;
			transition-delay: 0.1s;
		}
	}
}

@media (--media-tablet) {
	.header {
		padding: 0 10px;
	}
}

@media (--media-desktop) {
	.header {
		position: fixed;
		top: 40px;
		height: 80px;
		background-color: var(--transparent);
		transition:
			transform 0.3s,
			background-color 0.5s;

		&-wrapperFigure {
			display: flex;
			justify-content: center;
			width: 170px;
			height: 43px;
			position: relative;
			transition: transform 0.3s ease 0.1s;
		}

		&-wrapperOverlay {
			display: none;
		}
	}

	.fixHeader {
		.header {
			background-color: var(--white);
			transform: translateY(-40px);
			box-shadow: 0 2px 40px rgb(15 15 15 / 5%);

			.header-wrapperFigure {
				transform: scale(0.8);

				svg {
					fill: var(--black);
				}
			}
		}
	}
}
