.backToTop {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	background-color: var(--black);
	border-radius: 5px;
	position: fixed;
	bottom: 20px;
	right: 15px;
	cursor: pointer;
	box-shadow: 0 0 4px 0 rgb(0 0 0 / 20%);
	opacity: 0;
	visibility: hidden;
	transform: translateX(160%);
	transition:
		opacity 0.3s,
		visibility 0.3s,
		transform 0.3s;
	z-index: 10;

	&.show {
		opacity: 1;
		visibility: visible;
		transform: translateX(0);
	}

	svg {
		width: 15px;
		height: 15px;
		fill: var(--brown);
	}
}
